<template>
  <div id="CommonCrumbTab">
    <!-- Breadcrumb 面包屑 Tabs 标签页 -->
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="current.path">{{current.label}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <tab></tab> -->

  </div>
</template>

<script>

// import Tab from 'components/CommonTab.vue'

import { mapState } from 'vuex'
export default {
  components: {
    // Tab
  },
  computed: {
    ...mapState({
      current: state => state.tab.currentMenu
    })
  },
  data () {
    return {

    }
  }

}
</script>

<style lang="scss" scoped>
#CommonCrumbTab {
  // padding-top: $padding-15;
  // padding:20px 0;
  height: $header-height;
  display: flex;
  align-items: center;
  .el-breadcrumb {
  }
  .CommonMainCon {
  }
}
</style>
