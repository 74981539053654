<template>
  <div class="commonAside">
    <!-- 切换菜单按钮 -->

    <el-menu :default-active="activeIndex" unique-opened @select="handleSelect" :collapse="isCollapse"
      :collapse-transition="false" router>
      <template v-for="item in asideMenuList">
        <!-- 最后一级菜单 -->
        <el-menu-item :index="item.path" v-if="!item.children" :key="item.name" @click="clickMenuItem(item)">
          <!-- 一级菜单模板区域 -->
          <i class="el-icon-menu"></i>
          <span>{{ item.label }}</span>
        </el-menu-item>
        <!-- 菜单下还有子菜单 -->
        <el-submenu :index="item.path" v-if="item.children" :key="item.name">
          <template slot="title">
            <span>{{ item.label }}</span>
          </template>
          <!-- 嵌套二级菜单 -->
          <el-menu-item :index="item.path + subItem.path" v-for="subItem in item.children" :key="subItem.name"
            @click="clickMenuItem(subItem)">
            <!-- 二级菜单模板区域 -->
            <i class="el-icon-menu"></i>
            <span>{{ subItem.label }}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    asideMenuConfig: Object,
    asideMenuList: Array
  },

  data() {
    return {
      // 选中的index
      activeIndex: '',
      // 是否水平折叠收起菜单（仅在 mode 为 vertical 时可用）
      isCollapse: false
    }
  },
  mounted() { },
  computed: {},
  methods: {
    // 菜单激活回调	index: 选中菜单项的 index, indexPath: 选中菜单项的 index path
    handleSelect(index, indexPath) {
      this.activeIndex = index

    },

    // 折叠左侧菜单
    toggleCollapse() {
      this.isCollapse = !this.asideMenuConfig.isCollapse
      this.$emit('handleMenuSwitch', this.isCollapse)
    },
    // 点击的菜单对象添加到 Tabs 标签页
    clickMenuItem(item) {
      this.$store.commit('selectMenu', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.commonAside {
  position: relative;
  height: 100%;
  overflow: hidden;

  .el-button {
    position: absolute;
    top: 0;
    z-index: 1;
    box-sizing: border-box;
    font-weight: 700;
    width: 100%;
    height: 40px;
    border-radius: 0;
    letter-spacing: 2px;
    background: $aside-back-color;
    // cursor: pointer;
  }

  .el-menu {
    position: absolute;
    top: 0;

    // height: calc(100% - 40px);
    height:100%;
    box-sizing: border-box;
    overflow-y: scroll;
    width: 100%;
    background: #fff;
    border: none;
  }

  /deep/ .el-menu::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }

  // 滚动条的滑块
  /deep/ .el-menu::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 3px;
  }
}
</style>
