<template>
  <el-container id="Home">

    <!-- 头部 -->
    <el-header class="Home-header">
      <div class="l-content">
        <!-- ~assets/img/logo.png -->
        <img src="../assets/img/唐龙视界logo_画板 1.svg" alt="公司logo" class="logo">
        <!-- 面包屑 tab标签 -->
        <el-header class="Emain-header">
          <CommonCrumbTab></CommonCrumbTab>
        </el-header>
      </div>
      <div class="r-content">
        <el-dropdown trigger="click" size="small">
          <span class="el-dropdown-link">
            <img :src="userImg" alt="" class="user">
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>个人中心</el-dropdown-item>
            <el-dropdown-item @click.native="logOff">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>

    <!-- 内容区域 侧边栏 和 内容 -->
    <el-container class="e-container-aside-main">
      <!-- 侧边导航栏 -->
      <el-aside :width="asideMenuConfig.isCollapse ? '64px' : '210px'">
        <CommonAside :asideMenuConfig="asideMenuConfig" :asideMenuList="asideMenuList"
          @handleMenuSwitch="handleMenuSwitch"></CommonAside>
      </el-aside>

      <el-container id="Emain">
        <!-- 面包屑 tab标签 -->
        <!-- <el-header class="Emain-header">
          <CommonCrumbTab></CommonCrumbTab>
        </el-header> -->

        <el-main>
          <router-view></router-view>
        </el-main>

      </el-container>

    </el-container>
  </el-container>
</template>

<script>

import { mapState } from 'vuex'
import CommonAside from 'components/CommonAside'
import CommonCrumbTab from 'components/CommonCrumbTab'

export default {
  components: {
    CommonAside,
    CommonCrumbTab
  },
  data() {
    return {
      userImg: require('@/assets/img/user-default.jpg'),
      // 左侧菜单数据
      asideMenuConfig: {
        // 是否水平折叠收起菜单（仅在 mode 为 vertical 时可用）
        isCollapse: false
      }
      // 列表数据
      // asideMenuList: [],

    }
  },


  computed: {
    ...mapState({
      current: state => state.tab.currentMenu
    }),

    asideMenuList: function () {
      return (this.$store.state.aside.menu).sort((v1, v2) => v1.id - v2.id)
    }
  },

  methods: {
    // 水平折叠收起菜单
    handleMenuSwitch(val) {
      this.asideMenuConfig.isCollapse = val
      console.log(this.asideMenuList)
    },
    // 退出登录
    logOff() {
      console.log('退出登录')
      this.$confirm('确认退出登录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push('/login')
      }).catch(() => {
        this.$message.info('已取消');
      });

    }
  }

}
</script>

<style lang="scss" scoped>
#Home {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;

  // 头部内容
  .Home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-height !important;
    color: $header-color;

    // background: $header-back-color;
    .l-content {
      // width: 180px;
      display: flex;
      align-items: center;
      height: 100%;

      .logo {
        // width: 180px;
        width: 160px;
        margin-right: 20px;
        height: 80%;
      }
    }

    .r-content {
      .user {
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
    }
  }

  // 侧边栏和内容区域
  .e-container-aside-main {
    flex: 1;
    height: calc(100% - #{$header-height});
    background: $e-container-aside-main;

    .el-aside {
      height: 100%;
      box-sizing: border-box;
      border-right: 1px solid #e6e6e6;
    }

    .e-header-crumb-tab {
      height: auto !important;
      padding: 0;
    }

    #Emain {
      display: flex;
      display: -webkit-flex;
      // 主轴方向
      flex-direction: column;

      .Emain-header {
        // height: auto !important;
        background: #fff;
      }

      .el-main {
        flex: 1;
        box-sizing: border-box;
        background: #fff;
        margin: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 1px rgba(80, 80, 80, 0.2);
        padding-bottom: 0px;
      }
    }
  }
}
</style>
